import { FunctionComponent } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AppRoute from '../constants/AppRoute';
import { AppThunkDispatch } from '../types/redux';

/* region actions */
import fetchUser from '../actions/user/fetchUser';
import getAccounts from '../actions/account/getAccounts';
import getAlerts from '../actions/alert/getAlerts';
import getSubscription from '../actions/subscription/getSubscription';
import getOrders from '../actions/order/getOrders';
/* endregion */
/* region layouts [ */
import AuthLayout from './authLayout/AuthLayout';
import RootLayout from './rootLayout/RootLayout';
/* endregion */
/* region pages */
import HomePage from '../pages/homePage/HomePage';
import ProfilePage from '../pages/profilePage/ProfilePage';
import RegistrationPage from '../pages/registrationPage/RegistrationPage';
import AboutPage from '../pages/aboutPage/AboutPage';
import TariffsPage from '../pages/tariffsPage/TariffsPage';
import FaqPage from '../pages/faqPage/FaqPage';
import ContactsPage from '../pages/contactsPage/ContactsPage';
import PrivacyPage from '../pages/privacyPage/PrivacyPage';
import ResetPassword from '../pages/resetPassword/ResetPassword';
import ProfileApiPage from '../pages/profileApiPage/ProfileApiPage';
import NotFoundPage from '../pages/notFoundPage/NotFoundPage';
import ProfileOrdersPage from '../pages/profileOrdersPage/ProfileOrdersPage';
import ProfileAlertsPage from '../pages/profileAlertsPage/ProfileAlertsPage';
import FailedPaymentPage from '../pages/failedPaymentPage/FailedPaymentPage';
import SuccessfulPaymentPage from '../pages/successfulPaymentPage/SuccessfulPaymentPage';
import OfferPage from '../pages/offerPage/OfferPage';
/* endregion */

const AppRouter: FunctionComponent = () => {
    const dispatch: AppThunkDispatch = useDispatch();

    const router = createBrowserRouter([
        {
            path: AppRoute.ROOT,
            element: <RootLayout />,
            loader: ({ request, params }) => {
                dispatch(fetchUser());
                return null;
            },
            children: [
                {
                    index: true,
                    element: <HomePage />
                },
                {
                    path: AppRoute.PROFILE,
                    element: <AuthLayout isRequiredAuth />,
                    loader: ({ request, params }) => {
                        dispatch(getAccounts());
                        dispatch(getSubscription());
                        return null;
                    },
                    children: [
                        {
                            index: true,
                            element: <ProfilePage />
                        },
                        {
                            path: AppRoute.PROFILE_API,
                            element: <ProfileApiPage />,
                            loader: ({ request, params }) => {
                                return null;
                            }
                        },
                        {
                            path: AppRoute.PROFILE_ORDERS,
                            element: <ProfileOrdersPage />,
                            loader: ({ request, params }) => {
                                dispatch(getOrders());
                                return null;
                            }
                        },
                        {
                            path: AppRoute.PROFILE_ALERTS,
                            element: <ProfileAlertsPage />,
                            loader: ({ request, params }) => {
                                dispatch(getAlerts());
                                return null;
                            }
                        }
                    ]
                },
                {
                    path: AppRoute.REGISTRATION,
                    element: <RegistrationPage />
                },
                {
                    path: AppRoute.ABOUT,
                    element: <AboutPage />
                },
                {
                    path: AppRoute.TARIFFS,
                    element: <TariffsPage />
                },
                {
                    path: AppRoute.FAQ,
                    element: <FaqPage />
                },
                {
                    path: AppRoute.CONTACTS,
                    element: <ContactsPage />
                },
                {
                    path: AppRoute.PRIVACY,
                    element: <PrivacyPage />
                },
                {
                    path: AppRoute.OFFER,
                    element: <OfferPage />
                },
                {
                    path: AppRoute.RESET_PASSWORD,
                    element: <ResetPassword />
                },
                {
                    path: '*',
                    element: <NotFoundPage />
                },
                {
                    path: AppRoute.FAQ_TEST,
                    element: <FaqPage />
                },
                {
                    path: AppRoute.SUCCESSFUL_PAYMENT,
                    element: <SuccessfulPaymentPage />
                },
                {
                    path: AppRoute.FAILED_PAYMENT,
                    element: <FailedPaymentPage />
                }
            ]
        }
    ]);

    return <RouterProvider router={router} />;
};

export default AppRouter;
