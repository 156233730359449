export const SUBSCRIPTION_PUBLIC_ID = 'pk_63127b5ea214728b255f783297461';
export const SUBSCRIPTION_AMOUNT = 500;
export const SUBSCRIPTION_RECEIPT = {
    Items: [
        {
            label: 'Оплата тарифа PRO',
            price: SUBSCRIPTION_AMOUNT,
            quantity: 1.0,
            amount: SUBSCRIPTION_AMOUNT,
            vat: null,
            method: 4
        }
    ],
    taxationSystem: 5,
    amounts: {
        electronic: SUBSCRIPTION_AMOUNT
    }
};
