import React, { FC, PropsWithChildren, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import queryString from 'query-string';
import Wrapper from '../../components/wrapper/Wrapper';
import Header from './components/header/Header';
import CookiePopup from '../../components/cookiePopup/CookiePopup';
import useUserConsent, { ConsentType } from '../../hooks/useUserConsent';
import styles from './RootLayout.module.scss';
import Footer from './components/footer/Footer';

interface Props extends PropsWithChildren {}

const RootLayout: FC<Props> = () => {
    const location = useLocation();
    const {
        isLoaded: isUserConsentLoaded,
        consentList,
        isUserConsentedCookies,
        updateCookieConsent
    } = useUserConsent();

    useEffect(() => {
        const query = queryString.parse(location.search);
        if (query?.utm_source) {
            localStorage.setItem('utm_source', query.utm_source.toString());
        }
    }, [location.search]);

    return (
        <>
            {isUserConsentLoaded && isUserConsentedCookies && consentList.includes(ConsentType.ANALYTICS) && (
                <YMInitializer
                    accounts={[94228922]}
                    options={{
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true
                    }}
                />
            )}
            <div className={styles.container}>
                <Header />
                <div className={styles.content}>
                    <Wrapper>
                        <Outlet />
                    </Wrapper>
                </div>
                <Footer />
            </div>
            <CookiePopup
                isUserConsentLoaded={isUserConsentLoaded}
                isUserConsentedCookies={isUserConsentedCookies}
                updateCookieConsent={updateCookieConsent}
            />
        </>
    );
};

export default RootLayout;
