import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './TariffOpportunity.module.scss';

export interface Feature {
    isInactive?: boolean;
    title: string | JSX.Element;
}

interface Props {
    data: Feature;
}

const TariffFeature: FC<Props> = ({ data }) => {
    return <div className={classNames(styles.container, { [styles.inactive]: data.isInactive })}>{data.title}</div>;
};

export default TariffFeature;
