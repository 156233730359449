import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { ReduxState } from '../../../../redux/rootReducer';
import selectUser from '../../../../selectors/selectUser';
import Button from '../../../../components/button/Button';
import { SubscriptionType } from '../../../../types/api';
import { SUBSCRIPTION_AMOUNT, SUBSCRIPTION_PUBLIC_ID, SUBSCRIPTION_RECEIPT } from '../../../../constants/subscription';
import formatDate from '../../../../utils/formatDate';
import ProfileTitle from '../profileTitle/ProfileTitle';
import { notifyError, notifySuccess } from '../../../../utils/notification';
import { AppThunkDispatch } from '../../../../types/redux';
import getSubscription from '../../../../actions/subscription/getSubscription';
import styles from './ProfileSubscription.module.scss';

interface Props {
    className?: string;
}

const ProfileSubscription: FC<Props> = ({ className }) => {
    const { t } = useTranslation('dashboard');
    const dispatch: AppThunkDispatch = useDispatch();
    const user = useSelector(selectUser);
    const subscription = useSelector((state: ReduxState) => state.subscription.subscription);

    const handleSubmit = useCallback(() => {
        if (!user) {
            return;
        }

        try {
            // @ts-ignore
            const widget = new cp.CloudPayments();
            widget.charge(
                {
                    publicId: SUBSCRIPTION_PUBLIC_ID,
                    amount: SUBSCRIPTION_AMOUNT,
                    email: user.email,
                    description: 'Оплата тарифа PRO',
                    currency: 'RUB',
                    data: {
                        CloudPayments: {
                            CustomerReceipt: { ...SUBSCRIPTION_RECEIPT, email: user.email }
                        }
                    }
                },
                function (options: any) {
                    console.log(options);
                    dispatch(getSubscription());
                    dispatch(notifySuccess({ message: 'Оплата прошла успешно. Тариф будет обновлен через 1-5 мин.' }));
                    // reset();
                },
                function (reason: any, options: any) {
                    console.error(reason, options);
                    dispatch(notifyError({ message: 'При оплате произошла ошибка' }));
                }
            );
        } catch (error) {
            console.error('Ошибка при создании подписки:', error);
        }
    }, [dispatch, user?.email]);

    return (
        <>
            <Helmet>
                <script src="https://widget.cloudpayments.ru/bundles/cloudpayments.js"></script>
            </Helmet>

            <ProfileTitle>
                {t('profile_tab.subscription_pre_title')}
                <span className={styles.blueText}>{subscription ? subscription.type : SubscriptionType.TRIAL}</span>
                {t('profile_tab.subscription_post_title')}
            </ProfileTitle>
            <div className={classNames(styles.tariffContent, className)}>
                {subscription ? (
                    <>
                        <div className={styles.tariffText}>{t('profile_tab.validity_period')}</div>
                        <div className={styles.daysLeft}>
                            {t('profile_tab.expiration_date')}
                            <span className={styles.blueText}>{formatDate(subscription.expiration)}</span>
                        </div>
                    </>
                ) : (
                    <div>
                        {t('profile_tab.current_trial_plan')}
                        <br />
                        {t('profile_tab.upgrade_pro')}
                        <br />
                        <br />
                        {t('profile_tab.tariff_price')}
                        <br />
                        {user?.isHasBonus ? t('profile_tab.bonus') : null}
                    </div>
                )}
                <div className={styles.buttonBlock}>
                    <Button className={styles.button} onClick={handleSubmit}>
                        {subscription ? t('profile_tab.prolong_subscription') : t('profile_tab.upgrade_to_pro')}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default ProfileSubscription;
