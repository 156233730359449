import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'rc-table/lib/interface';
import { useTranslation } from 'react-i18next';
import ProfileMenu from '../../components/profileMenu/ProfileMenu';
import { ReduxState } from '../../redux/rootReducer';
import formatDate from '../../utils/formatDate';
import { Direction, Order, OrderStatus, OrderType } from '../../types/api';
import { SIZE_ELEMENTS, START_PAGE } from '../../constants/page';
import getOrders, { OrdersFilterParam } from '../../actions/order/getOrders';
import ForkedTable from '../../components/forkedTable/ForkedTable';
import { AppThunkDispatch } from '../../types/redux';
import styles from './ProfileOrdersPage.module.css';

const ProfileOrdersPage: FC = () => {
    const { t } = useTranslation('dashboard');

    const DIRECTION_TITLE: Record<Direction, string> = {
        [Direction.BUY]: t('table.content.direction', { context: 'buy' }),
        [Direction.SELL]: t('table.content.direction', { context: 'sell' })
    };

    const ORDER_STATUS_TITLE: Record<OrderStatus, string> = {
        [OrderStatus.NEW]: t('table.content.order_status', { context: 'new' }),
        [OrderStatus.CREATED]: t('table.content.order_status', { context: 'created' }),
        [OrderStatus.REJECTED]: t('table.content.order_status', { context: 'rejected' })
    };

    const ORDER_TYPE_TITLE: Record<OrderType, string> = {
        [OrderType.MARKET]: t('table.content.order_type', { context: 'market' }),
        [OrderType.LIMIT]: t('table.content.order_type', { context: 'limit' })
    };

    const columns: ColumnsType<any> = [
        {
            title: t('table.header.date'),
            dataIndex: 'createdDate',
            key: 'createdDate',
            width: 150,
            render(value: number) {
                return value ? formatDate(value) : '-';
            }
        },
        {
            title: t('table.header.exchange'),
            dataIndex: 'exchange',
            key: 'exchange',
            width: 150,
            render(value: string, record: Order) {
                return record.instrument.exchange || '-';
            }
        },
        {
            title: t('table.header.ticker'),
            dataIndex: 'ticker',
            key: 'ticker',
            width: 150,
            render(value: string, record: Order) {
                return record.instrument.ticker || '-';
            }
        },
        {
            title: t('table.header.direction'),
            dataIndex: 'direction',
            key: 'direction',
            width: 150,
            render(value: Direction) {
                return DIRECTION_TITLE[value];
            }
        },
        {
            title: t('table.header.type'),
            dataIndex: 'type',
            key: 'type',
            width: 150,
            render(value: OrderType) {
                return ORDER_TYPE_TITLE[value];
            }
        },
        {
            title: t('table.header.price'),
            dataIndex: 'open',
            key: 'open',
            width: 150
        },
        {
            title: t('table.header.quantity'),
            dataIndex: 'quantity',
            key: 'quantity',
            width: 100
        },
        {
            title: t('table.header.status'),
            dataIndex: 'status',
            key: 'status',
            width: 150,
            render(value: OrderStatus) {
                return ORDER_STATUS_TITLE[value];
            }
        },
        {
            title: t('table.header.response_server'),
            dataIndex: 'responseServerJson',
            key: 'responseServerJson',
            width: 250,
            render(value: string) {
                return (
                    <div className={styles.responseServer} placeholder={value}>
                        {value}
                    </div>
                );
            }
        }
    ];

    const dispatch: AppThunkDispatch = useDispatch();
    const [filter, setFilter] = useState<OrdersFilterParam>({
        page: START_PAGE,
        size: SIZE_ELEMENTS
    });
    const ordersPageable = useSelector((state: ReduxState) => state.order.orders);

    const handleSearch = useCallback(
        (filter?: OrdersFilterParam) => {
            return dispatch(getOrders(filter));
        },
        [dispatch]
    );

    const handleChangeCurrentPage = useCallback(
        (page: number, size: number) => {
            const nextFilter = {
                ...filter,
                page,
                size
            };
            setFilter(nextFilter);
            return handleSearch(nextFilter);
        },
        [filter, handleSearch]
    );

    return (
        <div className={styles.container}>
            <ProfileMenu />

            <ForkedTable columns={columns} pageable={ordersPageable} onChange={handleChangeCurrentPage} indexColumn />
        </div>
    );
};

export default ProfileOrdersPage;
