import React, { FC, PropsWithChildren } from 'react';
import styles from './PrivacySubParagraph.module.scss';

interface Props extends PropsWithChildren {}

const PrivacySubParagraph: FC<Props> = ({ children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>{children}</div>
        </div>
    );
};

export default PrivacySubParagraph;
