import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Button, { ButtonType } from '../../components/button/Button';
import DefaultInput from '../../components/defaultInput/DefaultInput';
import ProfileMenu from '../../components/profileMenu/ProfileMenu';
import { ReduxState } from '../../redux/rootReducer';
import ForkedSelect from '../../components/forkedSelect/ForkedSelect';
import { AppThunkDispatch } from '../../types/redux';
import saveAccounts from '../../actions/account/saveAccounts';
import { Account, Exchange, SubscriptionType } from '../../types/api';
import { REQUIRED_FIELD } from '../../constants/validation';
import { ACTIONS_LOG_LEVEL, createLogger } from '../../utils/logger';
import formatDate from '../../utils/formatDate';
import { notifyError, notifyInfo, notifySuccess } from '../../utils/notification';
import PlusIcon, { PlusIconColor, PlusIconSize, PlusIconType } from '../../components/plusIcon/PlusIcon';
import ProfileRow from './components/profileRow/ProfileRow';
import { ReactComponent as CopyIcon } from './icons/copyIcon.svg';
import Popup from '../../components/popup/Popup';
import ProfileInstruction from './components/profileInstruction/ProfileInstruction';
import Switch from '../../components/switch/Switch';
import styles from './ProfileApiPage.module.scss';

interface FormValues {
    accounts: Account[];
}

const exchanges = [
    // { value: Exchange.TINKOFF, label: 'Tinkoff Invest' },
    { value: Exchange.ALOR_BROKER, label: 'АЛОР БРОКЕР' }
    // { value: Exchange.BINANCE_SPOT, label: 'Binance Spot' },
    // { value: Exchange.BINANCE_FUTURES, label: 'Binance Futures' }
];

const conditionRequired = Yup.string()
    .required(REQUIRED_FIELD)
    .when('isDeleted', {
        is: true,
        then: (schema) => schema.notRequired()
    })
    .when('createdDate', {
        is: (value) => Boolean(value),
        then: (schema) => schema.notRequired()
    });

const schema = Yup.object().shape({
    accounts: Yup.array().of(
        Yup.object().shape({
            isDeleted: Yup.bool(),
            createdDate: Yup.string(),
            // id: conditionRequired,
            exchange: conditionRequired
        })
    )
});

const logger = createLogger('ProfileApiPage', ACTIONS_LOG_LEVEL);

const ProfileApiPage: FC = () => {
    const { t } = useTranslation('dashboard');
    const dispatch: AppThunkDispatch = useDispatch();

    const [isPopupShown, setIsPopupOpened] = useState(false);
    const [activeAccount, setActiveAccount] = useState<Account>();

    const accounts = useSelector((state: ReduxState) => state.account.accounts);
    const subscription = useSelector((state: ReduxState) => state.subscription.subscription);

    const {
        register,
        control,
        reset,
        setError,
        watch,
        formState: { errors, isValid, isSubmitting },
        handleSubmit: onSubmit
    } = useForm<FormValues>({
        mode: 'onTouched',
        defaultValues: { accounts },
        resolver: yupResolver(schema)
    });

    const { fields, append, update } = useFieldArray({
        control,
        name: 'accounts',
        keyName: 'fieldKey'
    });

    const watchedFields = watch('accounts');

    useEffect(() => {
        reset({ accounts });
    }, [accounts]);

    const handleSubmit = useCallback(
        (values: FormValues) => {
            logger.debug('values', values.accounts);
            dispatch(saveAccounts(values.accounts))
                .then(() => {
                    dispatch(notifySuccess({ message: 'Ключи успешно сохранены' }));
                })
                .catch((message) => {
                    dispatch(notifyError({ message: 'При сохранении ключей произошла ошибка' }));
                    Object.entries(message).map(([key, value]) => {
                        setError(key as any, { message: value as string });
                    });
                });
        },
        [dispatch]
    );

    const handleRemove = (index: number) => {
        update(index, {
            ...fields[index],
            isDeleted: true
        });
        dispatch(notifyInfo({ message: 'Ключ будет удален после сохранения' }));
    };

    const handleCopyId = async (accountId?: string) => {
        if (!accountId) {
            return;
        }
        try {
            await navigator.clipboard.writeText(accountId);
            dispatch(notifySuccess({ message: 'Идентификатор успешно скопирован' }));
        } catch (error) {
            logger.error(error);
            dispatch(notifyError({ message: 'При копировании идентификатора произошла ошибка' }));
        }
    };

    const handleOpenInstruction = (account: Account) => {
        setActiveAccount(account);
        setIsPopupOpened(true);
    };

    return (
        <div className={styles.container}>
            <ProfileMenu />

            <div className={styles.title}>{t('api_tab.title')}</div>

            <div className={styles.section}>
                <div className={styles.text}>{t('api_tab.text', { context: 'keys' })}</div>
                <form className={styles.form} autoComplete="false" onSubmit={onSubmit(handleSubmit)}>
                    {fields?.map((account, index) => {
                        const placeholderKey = account.createdDate ? '***' : t('api_tab.enter_key');
                        const exchange = watchedFields?.[index].exchange as Exchange;

                        let apiKeyTitle = t('api_tab.api_key');
                        if (exchange === Exchange.TINKOFF) {
                            apiKeyTitle = t('api_tab.account_id');
                        } else if (exchange === Exchange.ALOR_BROKER) {
                            apiKeyTitle = t('api_tab.portfolio_id');
                        }

                        let secretKeyTitle = t('api_tab.secret_key');
                        if (exchange === Exchange.TINKOFF) {
                            secretKeyTitle = t('api_tab.token');
                        } else if (exchange === Exchange.ALOR_BROKER) {
                            secretKeyTitle = t('api_tab.refresh_token');
                        }

                        return (
                            <div
                                key={account.id}
                                className={classNames(styles.group, {
                                    [styles.removed]: account.isDeleted
                                })}
                            >
                                <ProfileRow
                                    title={t('api_tab.key_type')}
                                    extra={
                                        <>
                                            {watchedFields?.[index].id ? (
                                                <span
                                                    className={styles.grayLink}
                                                    onClick={() => handleOpenInstruction(account)}
                                                >
                                                    {t('api_tab.instruction')}
                                                </span>
                                            ) : null}
                                            <div
                                                className={styles.removeIcon}
                                                {...register(`accounts.${index}.isDeleted`)}
                                                onClick={() => handleRemove(index)}
                                            >
                                                <PlusIcon
                                                    type={PlusIconType.CLOSE}
                                                    size={PlusIconSize.SMALL}
                                                    color={PlusIconColor.WHITE}
                                                />
                                            </div>
                                        </>
                                    }
                                >
                                    <div className={styles.fieldContainer}>
                                        <Controller
                                            render={({ field }) => {
                                                return (
                                                    <ForkedSelect
                                                        // autoFocus={!Boolean(account.createdDate)}
                                                        options={exchanges}
                                                        {...field}
                                                        isDisabled={account.isDeleted}
                                                        value={exchanges.find(
                                                            (exchange) => exchange.value === field.value
                                                        )}
                                                        aria-invalid={
                                                            errors.accounts?.[index]?.exchange ? 'true' : 'false'
                                                        }
                                                        onChange={(data) => field.onChange((data as any).value)}
                                                    />
                                                );
                                            }}
                                            name={`accounts.${index}.exchange`}
                                            control={control}
                                        />
                                        {errors.accounts?.[index]?.exchange && (
                                            <p className={styles.error}>
                                                {errors.accounts?.[index]?.exchange?.message}
                                            </p>
                                        )}
                                    </div>
                                </ProfileRow>

                                <ProfileRow title={apiKeyTitle}>
                                    <div className={styles.fieldContainer}>
                                        <DefaultInput
                                            type="password"
                                            autoComplete="new-password"
                                            className={styles.keyInput}
                                            placeholder={placeholderKey}
                                            disabled={account.isDeleted}
                                            aria-invalid={errors.accounts?.[index]?.apiKey ? 'true' : 'false'}
                                            {...register(`accounts.${index}.apiKey`)}
                                        />
                                        {errors.accounts?.[index]?.apiKey && (
                                            <p className={styles.error}>{errors.accounts?.[index]?.apiKey?.message}</p>
                                        )}
                                    </div>
                                </ProfileRow>

                                <ProfileRow title={secretKeyTitle}>
                                    <div className={styles.fieldContainer}>
                                        <DefaultInput
                                            type="password"
                                            autoComplete="new-password"
                                            className={styles.keyInput}
                                            placeholder={placeholderKey}
                                            disabled={account.isDeleted}
                                            aria-invalid={errors.accounts?.[index]?.secretKey ? 'true' : 'false'}
                                            {...register(`accounts.${index}.secretKey`)}
                                        />
                                        {errors.accounts?.[index]?.secretKey && (
                                            <p className={styles.error}>
                                                {errors.accounts?.[index]?.secretKey?.message}
                                            </p>
                                        )}
                                    </div>
                                </ProfileRow>

                                <ProfileRow title={t('api_tab.telegram_chat_id')}>
                                    <div className={styles.fieldContainer}>
                                        <DefaultInput
                                            type="text"
                                            className={styles.keyInput}
                                            aria-invalid={errors.accounts?.[index]?.telegramChatId ? 'true' : 'false'}
                                            {...register(`accounts.${index}.telegramChatId`)}
                                        />
                                        {errors.accounts?.[index]?.telegramChatId && (
                                            <p className={styles.error}>
                                                {errors.accounts?.[index]?.telegramChatId?.message}
                                            </p>
                                        )}
                                    </div>
                                </ProfileRow>

                                <ProfileRow title={t('api_tab.is_demo')}>
                                    <div className={styles.fieldContainer}>
                                        <Controller
                                            render={({ field }) => {
                                                return (
                                                    <Switch
                                                        {...field}
                                                        isEnabled={watchedFields?.[index].isDemo || false}
                                                        isLocked={subscription?.type !== SubscriptionType.PRO}
                                                        aria-invalid={
                                                            errors.accounts?.[index]?.isDemo ? 'true' : 'false'
                                                        }
                                                        onChange={(data) => field.onChange(data)}
                                                    />
                                                );
                                            }}
                                            name={`accounts.${index}.isDemo`}
                                            control={control}
                                        />
                                        {errors.accounts?.[index]?.isDemo && (
                                            <p className={styles.error}>{errors.accounts?.[index]?.isDemo?.message}</p>
                                        )}
                                    </div>
                                </ProfileRow>

                                <ProfileRow title={t('api_tab.tradingview_id')}>
                                    <div
                                        className={classNames(styles.fieldContainer, styles.disabledContainer)}
                                        onClick={() => handleCopyId(watchedFields?.[index].id)}
                                    >
                                        <DefaultInput
                                            type="text"
                                            className={classNames(styles.keyInput)}
                                            disabled
                                            aria-invalid={errors.accounts?.[index]?.id ? 'true' : 'false'}
                                            {...register(`accounts.${index}.id`)}
                                        />
                                        {watchedFields?.[index].id ? <CopyIcon className={styles.copyIcon} /> : null}
                                        {errors.accounts?.[index]?.id && (
                                            <p className={styles.error}>{errors.accounts?.[index]?.id?.message}</p>
                                        )}
                                    </div>
                                </ProfileRow>

                                <ProfileRow title={t('api_tab.created_date')}>
                                    <div className={styles.fieldContainer}>
                                        <DefaultInput
                                            type="text"
                                            className={styles.keyInput}
                                            disabled
                                            aria-invalid={errors.accounts?.[index]?.createdDate ? 'true' : 'false'}
                                            defaultValue={formatDate(account.createdDate)}
                                        />
                                        {errors.accounts?.[index]?.createdDate && (
                                            <p className={styles.error}>
                                                {errors.accounts?.[index]?.createdDate?.message}
                                            </p>
                                        )}
                                    </div>
                                </ProfileRow>
                            </div>
                        );
                    })}

                    <div className={styles.formStartControlsWrapper}>
                        <div className={styles.formStartControls}>
                            <Button
                                type={ButtonType.BUTTON}
                                onClick={() =>
                                    append({
                                        id: '',
                                        isDemo: subscription?.type !== SubscriptionType.PRO
                                    })
                                }
                            >
                                {t('api_tab.add_new_key')}
                            </Button>
                            <Button type={ButtonType.SUBMIT} disabled={isSubmitting || !isValid}>
                                {t('api_tab.save')}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>

            <div className={styles.section} id="instruction">
                <ProfileInstruction />
            </div>

            <Popup isOpened={isPopupShown} onTrigger={setIsPopupOpened} blocked>
                <ProfileInstruction account={activeAccount} />
            </Popup>
        </div>
    );
};

export default ProfileApiPage;
