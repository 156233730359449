import React, { FC } from 'react';
import PrivacyParagraph from './privacyParagraph/PrivacyParagraph';
import PrivacySubParagraph from './privacySubParagraph/PrivacySubParagraph';
import formatDate, { directDateMask } from '../../utils/formatDate';
import { LAST_OFFER_UPDATE } from '../../constants/companyData';
import styles from './OfferPage.module.scss';

const OfferPage: FC = () => {
    return (
        <div className={styles.container}>
            <h2 className={styles.title}>Публичная оферта на предоставление интеграционных услуг</h2>
            <PrivacyParagraph title={'Общие положения'}>
                <PrivacySubParagraph>
                    Настоящая публичная оферта (далее — «Оферта») является официальным предложением Интернет-сайта{' '}
                    <a href="https://tv-signals.com">tv-signals.com</a> (далее — «Исполнитель») заключить договор на
                    предоставление интеграционных услуг на условиях, изложенных ниже.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Оферта адресована физическим и юридическим лицам (далее — «Заказчик»).
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Акцептом (принятием) Оферты считается оплата Заказчиком услуг Исполнителя или регистрация и явное
                    согласие с условиями Оферты при оформлении заказа на сайте.
                </PrivacySubParagraph>
                <PrivacySubParagraph>Исполнитель и Заказчик совместно именуются «Стороны».</PrivacySubParagraph>
            </PrivacyParagraph>
            <PrivacyParagraph title={'Предмет Оферты'}>
                <PrivacySubParagraph>
                    Исполнитель предоставляет Заказчику услуги по интеграции торгового счета Заказчика у брокера с
                    платформой TradingView (далее — «Услуги»).
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Услуги предоставляются в объеме и на условиях, выбранных Заказчиком на сайте{' '}
                    <a href="https://tv-signals.com">tv-signals.com</a>.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Исполнитель оставляет за собой право изменять функционал, условия предоставления услуг и их
                    стоимость, уведомляя Заказчика путем публикации изменений на сайте. Изменения не применяются к уже
                    оплаченным Заказчиком услугам, если иное не согласовано Сторонами.
                </PrivacySubParagraph>
            </PrivacyParagraph>
            <PrivacyParagraph title={'Порядок предоставления услуг'}>
                <PrivacySubParagraph>
                    Для получения услуг Заказчик должен:
                    <br />- зарегистрироваться на сайте <a href="https://tv-signals.com">tv-signals.com</a>;
                    <br />- подключить свой торговый счет у брокера к платформе TradingView через интеграционный мост,
                    предоставленный Исполнителем;
                    <br />- оплатить выбранный тарифный план.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Услуга считается оказанной надлежащим образом и в полном объеме, если в течение 3 рабочих дней после
                    предоставления доступа Заказчик не предъявил мотивированные претензии.
                </PrivacySubParagraph>
            </PrivacyParagraph>
            <PrivacyParagraph title={'Стоимость услуг и порядок расчетов'}>
                <PrivacySubParagraph>
                    Стоимость услуг указана на сайте <a href="https://tv-signals.com">tv-signals.com</a> и может быть
                    изменена Исполнителем в одностороннем порядке. Изменения не применяются к уже оплаченным Заказчиком
                    услугам.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Оплата услуг производится путем перечисления денежных средств на расчетный счет Исполнителя или
                    через платежные системы, указанные на сайте.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Заказчик несет ответственность за правильность указанных платежных реквизитов.
                </PrivacySubParagraph>
            </PrivacyParagraph>
            <PrivacyParagraph title={'Права и обязанности Сторон'}>
                <PrivacySubParagraph>
                    Исполнитель обязуется:
                    <br />- предоставлять доступ к интеграционному мосту в соответствии с условиями Оферты;
                    <br />- обеспечивать конфиденциальность данных Заказчика;
                    <br />- оказывать техническую поддержку в рамках выбранного тарифного плана.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Заказчик обязуется:
                    <br />- своевременно оплачивать услуги;
                    <br />- использовать интеграционный мост исключительно в законных целях;
                    <br />- не передавать доступ к услугам третьим лицам без согласия Исполнителя.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Исполнитель вправе приостановить оказание услуг в случае нарушения Заказчиком условий Оферты.
                </PrivacySubParagraph>
            </PrivacyParagraph>
            <PrivacyParagraph title={'Ответственность Сторон'}>
                <PrivacySubParagraph>
                    Исполнитель не несет ответственности за:
                    <br />- результаты торговых операций, совершенных Заказчиком с использованием интеграционного моста;
                    <br />- невозможность оказания услуг по причинам, не зависящим от него (например, сбои в работе
                    интернета, технические неполадки у брокера или на платформе TradingView).
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Исполнитель несет ответственность за техническую исправность интеграционного моста и устранение
                    неисправностей в разумные сроки.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Заказчик несет полную ответственность за:
                    <br />- результаты использования интеграционного моста;
                    <br />- достоверность предоставленных данных и соблюдение условий Оферты.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Исполнитель не является стороной в отношениях между Заказчиком и брокером или платформой TradingView
                    и не несет ответственности за их действия или бездействие.
                </PrivacySubParagraph>
            </PrivacyParagraph>
            <PrivacyParagraph title={'Конфиденциальность'}>
                <PrivacySubParagraph>
                    Исполнитель обязуется не разглашать персональные данные и торговую информацию Заказчика, за
                    исключением случаев, предусмотренных законодательством.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Заказчик соглашается на обработку своих персональных данных в соответствии с Политикой
                    конфиденциальности, размещенной на сайте.
                </PrivacySubParagraph>
            </PrivacyParagraph>
            <PrivacyParagraph title={'Отказ от ответственности'}>
                <PrivacySubParagraph>
                    Исполнитель не гарантирует, что использование услуг приведет к каким-либо конкретным результатам.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Исполнитель не несет ответственности за убытки, упущенную выгоду или иные финансовые потери
                    Заказчика, возникшие в результате использования услуг.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Заказчик понимает и принимает все риски, связанные с торговлей на финансовых рынках, и использует
                    услуги на свой страх и риск.
                </PrivacySubParagraph>
            </PrivacyParagraph>
            <PrivacyParagraph title={'Заключительные положения'}>
                <PrivacySubParagraph>
                    Оферта вступает в силу с момента ее публикации на сайте{' '}
                    <a href="https://tv-signals.com">tv-signals.com</a> и действует до момента отзыва Исполнителем.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Исполнитель вправе вносить изменения в Оферту, уведомляя Заказчика путем публикации новой версии на
                    сайте.
                </PrivacySubParagraph>
                <PrivacySubParagraph>
                    Все споры и разногласия решаются путем переговоров, а при недостижении согласия — в судебном порядке
                    по месту нахождения Исполнителя.
                </PrivacySubParagraph>
            </PrivacyParagraph>
            <PrivacyParagraph title={'Реквизиты Исполнителя'}>
                Наименование: Индивидуальный предприниматель Тесленко Роман Сергеевич
                <br />
                ИНН: 564604295927
                <br />
                Расчетный счет: 40802810420000225173
                <br />
                Банк: ООО "Банк Точка"
                <br />
                БИК: 044525104
                <br />
                Корреспондентский счет: 30101810745374525104
            </PrivacyParagraph>

            <div className={styles.lastRefresh}>
                Последнее обновление: {formatDate(LAST_OFFER_UPDATE, directDateMask)}
            </div>
        </div>
    );
};

export default OfferPage;
