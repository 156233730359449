import React, { FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import TariffItem, { Tariff } from './components/tariffItem/TariffItem';
import HomePageTitle from '../homePageTitle/HomePageTitle';
import getStringKey from '../../../../utils/getStringKey';
import { SubscriptionType } from '../../../../types/api';
import styles from './TariffsBlock.module.scss';

interface Props {
    className?: string;
}

const TariffsBlock: FC<Props> = ({ className }) => {
    const { t } = useTranslation('homePage');
    const { t: tCommon } = useTranslation('common');

    const tariffs: Tariff[] = [
        {
            type: SubscriptionType.TRIAL,
            title: tCommon('tariffs.trial.title'),
            price: tCommon('tariffs.trial.price'),
            priceExtra: tCommon('tariffs.trial.price_extra'),
            link: tCommon('tariffs.trial.link'),
            features: [
                { title: tCommon('tariff_features.item', { context: 1 }) },
                {
                    title: (
                        <Trans
                            t={tCommon}
                            i18nKey="tariff_features.item"
                            context={'2'}
                            components={{
                                Link: <a href="https://alor.dev/docs/api/quick-start/dev-env/" target={'_blank'} />
                            }}
                        />
                    )
                }
            ]
        },
        {
            type: SubscriptionType.PRO,
            title: tCommon('tariffs.pro.title'),
            price: tCommon('tariffs.pro.price'),
            priceExtra: tCommon('tariffs.pro.price_extra'),
            link: tCommon('tariffs.pro.link'),
            features: [
                { title: tCommon('tariff_features.item', { context: 1 }) },
                {
                    title: (
                        <Trans
                            t={tCommon}
                            i18nKey="tariff_features.item"
                            context={'2'}
                            components={{
                                Link: <a href="https://alor.dev/docs/api/quick-start/dev-env/" target={'_blank'} />
                            }}
                        />
                    )
                },
                {
                    title: (
                        <Trans
                            t={tCommon}
                            i18nKey="tariff_features.item"
                            context={'3'}
                            components={{
                                Link: <a href="https://alor.dev/docs/api/quick-start/prod-env/" target={'_blank'} />
                            }}
                        />
                    )
                }
            ]
        }
    ];

    return (
        <div className={classNames(styles.container, className)} id="tariffsBlock">
            <HomePageTitle>{t('tariffs_block.title')}</HomePageTitle>
            <div className={styles.items}>
                {tariffs.map((tariff, index) => (
                    <TariffItem tariff={tariff} key={getStringKey(index, 'tariffItem')} />
                ))}
            </div>
        </div>
    );
};

export default TariffsBlock;
